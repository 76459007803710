<template>
  <!-- Scrollable modal -->
  <div class="text-center modal fade" id="cardnewsModal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
        <div class="border-0 modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="pb-5 modal-body">
          <swiper
            :slidesPerView="1"
            :spaceBetween="30"
            :pagination="{
              type: 'fraction',
            }"
            :navigation="true"
            :grabCursor="true"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide v-for="i in cardnews.img" :key="i">
              <img class="p-1" style="width: 100%; max-width: 500px;" :src="require(`@/assets/image/cardnews/${i}`)" alt="카드뉴스">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
  props: {
    cardnews: {
      type: Object,
      default() {
        return { 
          title: 'title',
          img: [],
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed() {
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  methods: {
  }
};
</script>

<style>
.swiper-button-next, .swiper-button-prev {
  color: #000000 !important;
}
.swiper-pagination {
  position: unset !important;
}
</style>
