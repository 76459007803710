<template>
  <!-- Scrollable modal -->
  <div class="text-center modal fade" id="projectModal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
        <div class="border-0 modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="pb-5 modal-body">
            <h1 class="pt-3 pb-3 fs-1">{{ content.title }}</h1>
            <p>{{ content.description }}</p>
            <h2 class="mt-5 mb-3">Tech Stack 📚</h2>
            <div>
              <img class="p-1" v-for="tech in content.tech" :key="tech" :src="tech" alt="사용기술">
            </div>
            <h2 class="mt-5 mb-3">Demo</h2>
            <a v-if="content.url" class="p-2 d-block" :href="content.url" target="_blank">운영 사이트 보러가기</a>
            <video v-if="content.video" :src="require(`@/assets/video/${content.video.name}.mp4`)" controls autoplay muted :style="'width:'+content.video.width+'%; object-fit: cover;'"></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default() {
        return { 
          title: 'title',
          description: '',
          tech: []
        }
      }
    }
  }
};
</script>

<!-- <style scoped>
  video {
    width: 100%;
    object-fit: cover;
  }
</style> -->
