<template>
  <main>
    <div class="container-fruid">
      <!-- 최상단 소개 -->
      <section class="wrap-header">
        <h1 class="fs-0">Really, Fun</h1>
        <div class="imgs">
            <img src="@/assets/image/hyemin.svg" alt="profile">
            <div class="sub-title">
                <span class="fs-0 f-border">#긍정</span>
                <span class="fs-0 f-border">#협업</span>
            </div>
        </div>
        <span class="fs-6">Web Designer / UI & Markup Developer</span>
      </section>

      <!-- 소개글 -->
      <section class="wrap-content width-l introduce">
        <div>
          <h2>
            안녕하세요. <br/>
            좋아하는 일을 하는 <br/>
            웹 디자이너 이혜민입니다.
          </h2>
          <p>
            Figma와 Sketch를 활용하여 UI 디자인을 하고, Zeplin과 Git으로 협업합니다.<br/>
            그리고 Vue.js, PHP, HTML5, CSS3, Javascript를 활용하여 웹 개발을 합니다.<br/>
            저의 취미는 만들기입니다. 공예디자인을 전공하여 손으로 만드는 것을 좋아합니다.<br/>
            뿐만 아니라, 온라인에서 Photoshop, Illustrator로 이미지나 로고를 만들거나, 코드로 페이지를 만드는 것도 무척이나 좋아합니다.<br/>
            저의 강점은 소통과 성장에 대한 열정입니다.<br/>
            기획자, 개발자와의 소통이 중요하다고 생각합니다. 원하는 목적을 이룰 수 있도록 개발과 디자인 경험을 바탕으로 경청하고, 이견을 조율합니다.<br/>
            디자이너는 끊임없는 공부와 성장을 필수로 하는 직업이라고 생각합니다.<br/>
            일하며 배우는 재미와 성장하는 저를 보며 뿌듯하고 흥미로웠습니다. 디자인은 제게 단순히 돈을 벌기 위한 수단이 아닌, 제 삶의 일부분이 되었습니다. 지속해서 발전하고 변화하는 업계의 특징에 발맞추어 스스로 성장하고, 사용자에게 좋은 서비스를 경험하게 하고 싶은 디자이너가 되고 싶습니다.
          </p>
        </div>
        <div>
          <ul class="links">
            <li><span class="fs-0 f-border">#Github</span><a href="https://github.com/Rosie-bb" class="fs-3" target="_blank">Rosie-bb</a></li>
            <li><span class="fs-0 f-border">#Age</span><span class="fs-3">33</span></li>
            <li><span class="fs-0 f-border">#Email</span><a href="mailto:lee@hyemin.kr" class="fs-3" target="_blank">lee@hyemin.kr</a></li>
          </ul>
        </div>
      </section>

      <!-- 기술 소개 -->
      <section class="width-fluid bg-gray-10 tech">
        <!-- <ul>
            <li class="fs-0 f-border">#Markup</li>
            <li class="fs-0 f-border">#Frontend</li>
            <li class="fs-0 f-border">#cardnews</li>
        </ul> -->
        <div class="wrap-content width-m align-items-center">
          <div>
            <h2>제가 잘 할 수 있는 <br/> 기술을 소개합니다.</h2>
            <ul>
              <li><b>Photoshop</b> 이미지 편집 및 디자인</li>
              <li><b>Illustrator</b> 아이콘 및 로고 디자인</li>
              <li><b>Indesign</b> 인쇄용 디자인</li>
              <li><b>Sketch, Figma</b> 웹 디자인</li>
              <li><b>Zeplin, Git</b> 협업</li>
              <li><b>HTML, CSS, Javascript</b> 웹 퍼블리싱</li>
            </ul>
          </div>
          <div class="z-index-10">
            <img src="@/assets/image/tech.png" alt="기술">
          </div>
        </div>
      </section>


      <!-- 디자인 프로젝트 소개 -->
      <section class="mb-5" style="margin-top: 200px;">
        <h2 class="pb-5 mb-5 text-center">제가 참여한 프로젝트 입니다.</h2>

        <!-- BI -->
        <div class="row justify-content-between" v-for="item in bi" :key="item">
          <div :class="[item.bg.content, 'col-xl-6 p-0 d-flex justify-content-xl-end justify-content-md-start ju']">
            <div class="col-xl-6">
              <div class="p-5 h-100 justify-content-center d-flex flex-column">
                <div class="h-auto justify-content-between d-flex flex-column">
                  <h2>{{ item.title }}</h2>
                  <p class="w-75">{{ item.content }}</p>
                  <div>
                    <p><b>기여도</b> {{ item.contribution }}%</p>
                    <p>
                      <b>프로그램 </b>
                      <span v-for="p in item.program" :key="p">{{ p }}<template v-if="p !== item.program[item.program.length - 1]">, </template></span>
                    </p>
                    <p v-if="item.url"><b>URL </b> <a :href="item.url">{{ item.url }}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 col-xl-6 d-flex justify-content-end">
            <div v-if="item.img" class="col-xl-12">
              <img style="width: 100%;" :src="require(`@/assets/image/${item.img}`)" alt="BI">
            </div>
          </div>
        </div>

        <!-- Cardnews -->
        <div class="row">
          <div :class="[cardnews.bg.content, 'col-xl-6 p-0 d-flex justify-content-xl-end justify-content-md-start']">
            <div class="col-xl-6">
              <div class="p-5 h-100 justify-content-center d-flex flex-column">
                <div class="h-auto justify-content-between d-flex flex-column">
                  <h2>{{ cardnews.title }}</h2>
                  <p class="w-75">{{ cardnews.content }}</p>
                  <div>
                    <p><b>기여도</b> {{ cardnews.contribution }}%</p>
                    <p>
                      <b>프로그램 </b>
                      <span v-for="p in cardnews.program" :key="p">{{ p }}<template v-if="p !== cardnews.program[cardnews.program.length - 1]">, </template></span>
                    </p>
                    <p v-if="cardnews.url"><b>URL </b> <a :href="cardnews.url" target="_blank">{{ cardnews.url }}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 col-xl-6">
            <div class="col-xl-12">
              <swiper
                :class="[cardnews.bg.desgin  === 'bg-black-100'? 'font-white-100' : '',cardnews.bg.desgin ,'p-5']"
                :autoplay="{
                  delay: 4000,
                  disableOnInteraction: false,
                }"
                :slidesPerView="1"
                :spaceBetween="30"
                :grabCursor="true"
                :modules="modules"
                :breakpoints="{
                  250: {
                    slidesPerView: 1,
                  },
                  375: {
                    slidesPerView: 1,
                  },
                  425: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1400 : {
                    slidesPerView: 3,
                  }
                }"
              >
                <swiper-slide v-for="(item, index) in cardnews.card" :key="item" class="d-flex flex-column align-items-start">
                  <img style="width: 100%;" :src="require(`@/assets/image/cardnews/${item.img[0]}`)" alt="cardnews">
                  <button class="mt-3 bg-transparent border-0 font-white-100 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#cardnewsModal" @click="selectedProject = index">more</button>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- Promotion -->
        <div class="row">
          <div :class="[promotion.bg.content, 'col-xl-6 p-0 d-flex justify-content-xl-end justify-content-md-start']">
            <div class="col-xl-6">
              <div class="p-5 h-100 justify-content-center d-flex flex-column">
                <div class="h-auto justify-content-between d-flex flex-column">
                  <h2>{{ promotion.title }}</h2>
                  <p class="w-75">{{ promotion.content }}</p>
                  <div>
                    <p><b>기여도</b> {{ promotion.contribution }}%</p>
                    <p>
                      <b>프로그램 </b>
                      <span v-for="p in promotion.program" :key="p">{{ p }}<template v-if="p !== promotion.program[promotion.program.length - 1]">, </template></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 col-xl-6">
            <div class="col-xl-12">
              <swiper
                :class="[promotion.bg.desgin  === 'bg-black-100'? 'font-white-100' : '',promotion.bg.desgin ,'p-5 justify-content-center align-items-center d-flex']"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                :slidesPerView="1"
                :spaceBetween="30"
                :grabCursor="true"
                :modules="modules"
                :breakpoints="{
                  250: {
                    slidesPerView: 1,
                  },
                  375: {
                    slidesPerView: 1,
                  },
                  425: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1400 : {
                    slidesPerView: 3,
                  }
                }"
              >
                <swiper-slide v-for="(item, index) in promotion.card" :key="item" class="d-flex flex-column align-items-start">
                  <img style="width: 100%;" :src="require(`@/assets/image/promotion/${item.img[0]}`)" alt="cardnews">
                  <button class="mt-3 bg-transparent border-0 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#promotionModal" @click="selectedProject = index">more</button>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- Detail Page -->
        <div class="row">
          <div :class="[detail.bg.content, 'col-xl-6 p-0 d-flex justify-content-xl-end justify-content-md-start']">
            <div class="col-xl-6">
              <div class="p-5 h-100 justify-content-center d-flex flex-column">
                <div class="h-auto justify-content-between d-flex flex-column">
                  <h2>{{ detail.title }}</h2>
                  <p class="w-75">{{ detail.content }}</p>
                  <div>
                    <p><b>기여도</b> {{ detail.contribution }}%</p>
                    <p>
                      <b>프로그램 </b>
                      <span v-for="p in detail.program" :key="p">{{ p }}<template v-if="p !== detail.program[detail.program.length - 1]">, </template></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 col-xl-6">
            <div class="col-xl-12">
              <swiper
                :class="[detail.bg.desgin  === 'bg-black-100'? 'font-white-100' : '',detail.bg.desgin ,'p-5 justify-content-center align-items-center d-flex']"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                :slidesPerView="1"
                :spaceBetween="30"
                :grabCursor="true"
                :modules="modules"
                :breakpoints="{
                  250: {
                    slidesPerView: 1,
                  },
                  375: {
                    slidesPerView: 1,
                  },
                  425: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1400 : {
                    slidesPerView: 3,
                  }
                }"
              >
                <swiper-slide v-for="(item, index) in detail.card" :key="item" class="d-flex flex-column align-items-start">
                  <!-- <div :style="{'background-image': 'url(' + require('@/assets/image/detail/'+item.img[0]) + ')', 'width': '100%', 'height': '250px'}"></div> -->
                  <img style="width: 100%;" :src="require(`@/assets/image/detail/${item.img[0]}`)" alt="cardnews">
                  <button class="mt-3 bg-transparent border-0 text-decoration-underline font-white-100" data-bs-toggle="modal" data-bs-target="#detailModal" @click="selectedProject = index">more</button>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

      </section>

      <!-- 디자인 관련 팝업 -->
      <CardnewsModal :cardnews="cardnews.card[selectedProject]"></CardnewsModal>
      <PromotionModal :promotion="promotion.card[selectedProject]"></PromotionModal>
      <DetailModal :detail="detail.card[selectedProject]"></DetailModal>

      <!-- 프로젝트 소개 -->
      <section class="text-center wrap-content flex-column">
        <div>
          <h2 class="mb-3">Web Design & UI Markup</h2>
          <span class="font-gray-100">클릭하시면, 자세히 볼 수 있어요.</span>
        </div>
      </section>

      

      <div class="container">
        <div class="row justify-content-md-center">
        <swiper
        class="p-5 mixed"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :modules="modules"
        :scrollbar="{ draggable: true }"
        :slides-per-view="4"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :breakpoints="{
          250: {
            slidesPerView: 1,
          },
          375: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1400 : {
            slidesPerView: 4,
          }
        }"
      >

        <swiper-slide v-for="(item, index) in project" :key="item">
          <button class="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#projectModal" @click="selectedProject = index"></button>
          <i :class="item.color">{{ item.icon }}</i>
          <span class="p-1 fs-5">{{ item.title }}</span>
          <div class="p-1 font-gray-100">
            <sapn class="p-1" v-for="s in item.stack" :key="s">#{{ s }}</sapn>
          </div>
          <sapn class="contribution font-blue-100">Contribution {{ item.contribution }}%</sapn>
        </swiper-slide>
      </swiper>
      </div>
      </div>
      

      <!-- 프로젝트 관련 파업 -->
      <ProjectModal :content="project[selectedProject]"></ProjectModal>

    </div>
  </main>

</template>

<script>
import { Scrollbar, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue'
import ProjectModal from '@/components/ProjectModal';
import CardnewsModal from '@/components/CardnewsModal';
import PromotionModal from '@/components/PromotionModal';
import DetailModal from '@/components/DetailModal';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

const project = [
  {
    idx: 0,
    color: "bg-purple-100 font-white-100",
    icon: "S",
    title: "쇼핑몰",
    stack: ["기획", "디자인", "개발"],
    contribution: "50",
    description: "건강기능식품 판매 쇼핑몰",
    tech: ["https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff",
          "https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff", 
          "https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A",
          "https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php",
          "https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter"],
    url: "https://app.mohe.store",
    video: {
      name: "app.mohe.store",
      width: "30"
    },
  },
  {
    idx: 1,
    color: "bg-orange-100",
    icon: "M",
    title: "브랜드 웹사이트",
    stack: ["개발"],
    contribution: "80",
    description: "브랜드 홍보용 웹사이트",
    tech: ["https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff",
          "https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff", 
          "https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A",
          "https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php",
          "https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter"],
    url: "https://mohe.life",
    video: {
      name: "mohe.life",
      width: "100"
    },
  },
  {
    idx: 2,
    color: "bg-blue-50",
    icon: "M",
    title: "웹사이트 관리자",
    stack: ["기획", "디자인", "개발"],
    contribution: "80",
    description: "웹사이트 관리하기 위한 대시보드",
    tech: ["https://img.shields.io/badge/-Vue-1A1A1A?style=for-the-badge&logo=vue.js", 
          "https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff",
          "https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff", 
          "https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A",
          "https://img.shields.io/badge/-Tailwind-%23F7DF1C?style=for-the-badge&logo=Tailwind&color=%230EA5E9"],
    video: {
      name: "admin.mohe.life",
      width: "100"
    },
  },
  {
    idx: 3,
    color: "bg-blue-dark-100 font-white-100",
    icon: "M",
    title: "대기열 관리자",
    stack: ["디자인", "개발"],
    contribution: "80",
    description: "리모컨 컨셉의 오프라인 매장에서 대기열을 관리하는 웹사이트",
    tech: ["https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff",
          "https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff", 
          "https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A",
          "https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php",
          "https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter",
          "https://img.shields.io/badge/-Bootstrap-0d6efd?style=for-the-badge&logo=Bootstrap&logoColor=ffffff"],
    video: {
      name: "offie.mohe.store",
      width: "30"
    },
  },
  {
    idx: 4,
    color: "bg-blue-100 font-white-100",
    icon: "P",
    title: "웹사이트",
    stack: ["기획", "디자인", "개발"],
    contribution: "100",
    description: "비즈니스 웹사이트",
    tech: ["https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff",
          "https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff", 
          "https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A",
          "https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php",
          "https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter"],
    url: "https://philodata.io/",
    video: {
      name: "philodata.io",
      width: "100"
    },
  },
]

const bi = [
{
    bg: {
      content: "bg-gray-10",
      desgin: "bg-white",
    },
    title: "BI",
    content: "혈당과 그래프를 조합한 심볼 형태의 혈당기 브랜드 로고입니다.",
    contribution: "100",
    program: ["Iiiustrator"],
    img: "BI.jpg"
  },
  {
    bg: {
      content: "bg-white",
      desgin: "bg-white",
    },
    title: "App Icon",
    content: "패션플러스 앱 아이콘 리뉴얼",
    contribution: "100",
    program: ["Photoshop","Iiiustrator"],
    img: "fashionplus_icon.jpg"
  },
]

const cardnews = {
  bg: {
    content: "bg-gray-10",
    desgin: "bg-black-100",
  },
  title: "Cardnews",
  content: "SNS 마케팅에 활용한 카드뉴스 디지인입니다. 내용은 출처에 해당하는 기관에 저작권 승인받은 후 사용하였습니다. 아래 주소에서 더 다양한 컨텐츠를 확인하실 수 있습니다.",
  contribution: "100",
  program: ["Photoshop", "Iiiustrator"],
  url: "https://www.instagram.com/gmate_official/",
  card: [
    {
      title: "카드뉴스",
      img: ['1.png','1_1.png','1_2.png','1_3.png','1_4.png','1_5.png','1_6.png','1_7.png','1_8.png','1_9.png'],
    },
    {
      title: "카드뉴스",
      img: ['2.png','2_1.png','2_3.png','2_4.png','2_5.png','2_6.png','2_7.png','2_8.png','2_9.png'],
    },
    {
      title: "카드뉴스",
      img: ['3.png','3_1.png','3_2.png','3_3.png','3_4.png','3_5.png','3_6.png','3_7.png'],
    },
    {
      title: "카드뉴스",
      img: ['4.png','4_1.png','4_2.png','4_3.png','4_4.png','4_5.png','4_6.png','4_7.png','4_8.png'],
    },
  ]
}

const promotion = {
  bg: {
    content: "bg-white",
    desgin: "bg-gray-10",
  },
  title: "Promotion",
  content: "기획전과 프로모션 디자인",
  contribution: "100",
  program: ["Photoshop", "Iiiustrator"],
  card: [
  {
      title: "프로모션명",
      img: ['1.jpg', '1_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['2.jpg', '2_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['3.jpg', '3_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['4.jpg', '4_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['5.jpg','5_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['6.jpg','6_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['7.jpg','7_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['8.jpg','8_1.jpg'],
    },
    {
      title: "프로모션명",
      img: ['9.jpg','9_1.jpg'],
    },
  ]
}

const detail = {
  bg: {
    content: "bg-gray-10",
    desgin: "bg-black-100",
  },
  title: "Detail Page & Visual Design",
  content: "상세페이지와 홍보물 디자인",
  contribution: "100",
  program: ["Photoshop", "Iiiustrator"],
  card: [
    {
      title: "사봉",
      img: ['1_1.jpg', '1.jpg'],
    },
    {
      title: "수프라",
      img: ['5_1.jpg', '5.jpg'],
    },
    {
      title: "리플렛 디자인",
      img: ['3_1.jpg', '3.jpg',],
    },
    {
      title: "명함 디자인",
      img: ['4_1.jpg', '4.jpg'],
    },
  ]
}

export default {
  components: {
    Swiper,
    SwiperSlide,
    ProjectModal,
    CardnewsModal,
    PromotionModal,
    DetailModal
  },
  setup() {
    const selectedProject = ref(false)
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log('slide change');
    // };
    return {
      // onSwiper,
      // onSlideChange,
      selectedProject,
      modules: [Scrollbar, Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {
      project,
      cardnews,
      bi,
      promotion,
      detail
    }
  },
  methods: {
  }
};
</script>

<style>
 /* 프로젝트 소개 */
 .swiper.mixed .swiper-wrapper {
  position: relative;
 }
 .swiper.mixed .swiper-wrapper a ,
 .swiper.mixed .swiper-wrapper button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .swiper.mixed .swiper-wrapper .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .swiper.mixed .swiper-wrapper .swiper-slide:nth-child(odd) {
    margin-top: 50px;
  }
  .swiper.mixed .swiper-wrapper .swiper-slide .stack {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper.mixed .swiper-wrapper .swiper-slide i {
    cursor: pointer;
    line-height: 90px;
    margin-bottom: 15px;
    display: inline-block;
    box-shadow: 10px 20px 20px rgba(0, 0, 0, .2);
    width: 90px;
    height: 90px;
    text-align: center;
    font-size: 2rem;
    border-radius: 11px;
    font-style: normal;
  }
  .swiper.mixed .swiper-wrapper .swiper-slide:hover i{
    transition: .5s;
    box-shadow: 10px 20px 20px rgba(0, 0, 0, .5);
    filter: brightness(120%);
  }
  /* 반응형 */
  @media (max-width: 375px) {
    .swiper.mixed .swiper-wrapper .swiper-slide:nth-child(odd) {
      margin-top: unset;
    }
  }
</style>