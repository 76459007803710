<template>
  <!-- Scrollable modal -->
  <div class="text-center modal fade" id="promotionModal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
        <div class="border-0 modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="pb-5 modal-body">
          <img v-if="promotion.img[1]" style="width: 100%;" :src="require(`@/assets/image/promotion/${promotion.img[1]}`)" alt="promotion">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    promotion: {
      type: Object,
      default() {
        return { 
          img: []
        }
      }
    }
  }
};
</script>

<!-- <style scoped>
  video {
    width: 100%;
    object-fit: cover;
  }
</style> -->
