<template>
  <footer class="p-5 d-flex justify-content-between align-items-center">
    <div>
      <a href="#" class="mr-1">Resume</a>
      <a href="https://github.com/Rosie-bb" target="_blank">Github</a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <span class="mr-1">© 2023 hyemin</span>
      <img src="@/assets/image/smile.svg" alt="smile icon">
    </div>
  </footer>
</template>